<template>
    <div class="form-wrap">
        <div class="content-w">
           <div class="form-box">
                <div class="form-title">预约体验产品及获取方案</div>
                <auto-row wrap="wrap">
                    <auto-col class="form-item" size="50%" min="400" max="700">
                        <auto-row items="center">
                            <auto-col class="label">公司名称</auto-col>
                            <auto-col class="value" size="auto">
                                <input class="inp" type="text">
                            </auto-col>
                        </auto-row>
                    </auto-col>
                    <auto-col class="form-item" size="50%" min="400" max="700">
                        <auto-row items="center">
                            <auto-col class="label">联系人</auto-col>
                            <auto-col class="value" size="auto">
                                <input class="inp" type="text">
                            </auto-col>
                        </auto-row>
                    </auto-col>
                    <auto-col class="form-item" size="50%" min="400" max="700">
                        <auto-row items="center">
                            <auto-col class="label">联系号码</auto-col>
                            <auto-col class="value" size="auto">
                                <input class="inp" type="text">
                            </auto-col>
                        </auto-row>
                    </auto-col>
                    <auto-col class="form-item" size="50%" min="400" max="700">
                        <auto-row items="center">
                            <auto-col class="label">您的需求</auto-col>
                            <auto-col class="value" size="auto">
                                <input class="inp" type="text">
                            </auto-col>
                        </auto-row>
                    </auto-col>
                </auto-row>
                <div style="margin-top:52px; text-align:center;">
                    <button class="submit-btn active">预约体验</button>
                </div>
           </div>
        </div>
    </div>
</template>
<script>
    import {
        autoRow,
        autoCol
    } from "@/components"
    export default {
        name: '',
        components: {
            autoRow,
            autoCol
        },
        props: [],
        data() {
            return {
            }
        },
        computed: { //计算属性

        },
        watch: {  //监听器

        },
        methods: {
        },
//        beforeCreate(){}, //组件实例刚被创建，组件属性计算之前，如data属性等
        created(){

        }, //组件实例创建完成，属性已绑定，但DOM还未生成，属性还不存在
//        beforeMount(){}, //模板编译/挂载之前
       mounted(){
       }, //模板编译/挂载之后(不保证组件已在document中)
       beforeUpdate(){
       }, //组件更新之前
       updated(){
       }, //组件更新之后
//        activated(){}, //for keep-alive,组件被激活时调用
//        deactivated(){}, //for keep-alive,组件被激活时调用
//        beforeDestory(){}, //组件销毁前调用
//        destoryed(){}, //组件销毁后调用
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-wrap{
    background-color:#2FA8D0;
    /* padding:72px 0; */
}
.content-w{
    padding-top:72px;
    padding-bottom:72px;
    box-sizing: content-box;
    max-width:1400px;
    padding-left:20px;
    padding-right:20px;
    margin:auto;
}
.form-box{
    width:100%;
    /* max-width:calc(100% - 60px); */
    min-height:618px;
    background: #FFFFFF;
    box-shadow: 0px 3px 8px 2px rgba(10,55,70,0.49);
    border-radius: 8px;
    padding:85px 50px;
    margin:0 auto;
}
.form-title{
    text-align:center;
    font-size: 40px;
    color: #2FA8D0;
    line-height: 60px;
}
.form-item{
    margin-top:90px;
    padding-right:30px;
}
.form-item .label{
    width:100px;
    text-align:right;
    padding-right:10px;
    font-size: 20px;
    color: #333333;
    line-height: 30px;
}
.form-item .value{
    height:60px;
    background: rgba(255,255,255,0.03);
}
.form-item .value .inp{
    width:100%;
    height:100%;
    border:none;
    font-size:22px;
    padding:0 10px;
    border: 1px solid #B7B7B7;
}
.form-item .value .inp:focus{
    outline-color:#2FA8D0;
}
.submit-btn{
    max-width:600px;
    min-width:400px;
    width:50%;
    background-color:#2FA8D0;
    border: 1px solid #2FA8D0;
    border:0 none;
    height:60px;
    line-height:60px;
    border-radius:4px;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 36px;
}
</style>
