<template>
    <div class="header-wrap">
        <div class="header-box">
            <auto-row class="content-w">
                <auto-col class="header-logo">
                    <auto-row class="max-size" items="center">
                        <auto-col size="2385px" items="center" style="padding-right:30px;">
                            <img class="logo-1" src="@/assets/img/header-logo.png" height="60" alt="logo" />
                            <img class="logo-2" src="@/assets/img/header-logo.png" width="60" alt="logo" />
                        </auto-col>
                        <auto-col size="auto"></auto-col>
                        <auto-col items="center">
                            <label class="icon-list active" for="is-nav-list-show-129389128"></label>
                        </auto-col>
                    </auto-row>
                </auto-col>
                <auto-col class="header-nav" size="auto" max="1100">
                    <input type="checkbox" id="is-nav-list-show-129389128">
                    <auto-row class="nav-list max-size">
                        <auto-col class="nav-item"
                            v-for="(nav,i) of navList"
                            :key="i"
                            size="1 1 160px"
                        >
                            <router-link class="nav-item-name middle"
                                :to="nav.path || $route.fullPath"
                                :title="'导航前往 '+nav.title"
                                :class="{'nav-item-select':isSelect(nav)}"
                                @click.native="nav.path && change(nav)"
                            >{{nav.title}}</router-link>
                            <div class="nav-item-children" v-if="nav.children && nav.children.length">
                                <div style="padding:12px 0;">
                                    <auto-row class="content-w" wrap="wrap" style="height:100%;">
                                        <auto-col
                                            class="nav-item-child"
                                            v-for="(childNav,index) of nav.children"
                                            :key="index"
                                            size="0 0 33.33%"
                                            justify="center"
                                        >
                                            <router-link class="nav-item-name middle"
                                                :to="childNav.path || $route.fullPath"
                                                :title="'导航前往 '+childNav.title"
                                                :class="{'nav-item-child-select':isSelect(childNav)}"
                                                @click.native="childNav.path && change(childNav)"
                                            >{{childNav.title}}</router-link>
                                        </auto-col>
                                    </auto-row>
                                </div>
                            </div>
                        </auto-col>
                    </auto-row>
                </auto-col>
            </auto-row>
        </div>
    </div>
</template>
<script>
    const debounce = (func, delay = 300) => {
        let timeout
        return function () {
            clearTimeout(timeout);
            return new Promise((resolve, reject) => {
                timeout = setTimeout(() => {
                    resolve(func.apply(this, arguments));
                }, delay);
            })
        }
    }
    const $parseTree = (arr, fn, pathName) => {
        var aa = [], bb=[];
        function B(arr, fn) {
            if (arr && arr[0]){
                for (var i = 0, child; child = arr[i]; i++) {
                        aa.push({
                        ...child,
                        index:i,
                        });
                        if (fn(child,i)){
                        bb.push({
                            ...child,
                            paths:aa.map((item) => {return item[pathName || 'name']}),
                            pathArr:[...aa],
                            indexs:aa.map((item) => {return item.index}),
                            parent:aa[aa.length-2] || null,
                        });
                        }
                    B(child.children, fn);
                    aa.pop();
                }
            }
        };
        B(arr, fn);
        return bb;
    };
    import {
        autoRow,
        autoCol
    } from "@/components"
    export default {
        name: '',
        components: {
            autoRow,
            autoCol
        },
        props: ['optionName'],
        data() {
            return {
                navList:[
                    {name:"home", path:"/", title:"首页"},
                    {name:"scheme", path:"", title:"养老方案", children:[
                        {name:"sqly_info", path:"/scheme/sqyl", title:"社区养老解决方案"},
                        {name:"scheme_3", path:"/scheme/3", title:"居家养老解决方案"},
                        {name:"scheme_4", path:"/scheme/4", title:"机构养老解决方案"},
                        // {name:"scheme_3", path:"/scheme/3", title:"养老评估解决方案"},
                        {name:"scheme_2", path:"/scheme/2", title:"家庭养老床位解决方案"},
                        // {name:"scheme_6", path:"/scheme/6", title:"设备物联解决方案"},
                    ]},
                    {name:"enterpriseApplication", path:"/enterprise/application", title:"企业应用"},
                    {name:"product", path:"", title:"产品中心", children:[
                        {name:"product_1", path:"/product/1", title:"智慧养老平台"},
                        {name:"product_2", path:"/product/2", title:"智能硬件支持"},
                        {name:"product_3", path:"/product/3", title:"移动应用平台"},
                        {name:"product_4", path:"/product/4", title:"可视化大屏"},
                    ]},
                    {name:"serve", path:"", title:"服务", children:[
                        {name:"serve_1", path:"/serve/1", title:"24小时呼叫中心"},
                        {name:"serve_2", path:"/serve/2", title:"辅具租赁"},
                        {name:"serve_3", path:"/serve/3", title:"适老化改造"},
                    ]},
                    {name:"aboutUs", path:"", title:"关于我们", children:[
                        {name:"aboutUs_1", path:"/aboutUs/1", title:"公司介绍"},
                        {name:"aboutUs_2", path:"/aboutUs/2", title:"加入我们"},
                        {name:"aboutUs_3", path:"/aboutUs/3", title:"联系我们"},
                        {name:"aboutUs_4", path:"/aboutUs/4", title:"新闻"},
                        // {name:"aboutUs_detail", path:"/aboutUs/detail", title:"新闻详情"},
                    ]},
                ]
            }
        },
        computed: { //计算属性

        },
        watch: {  //监听器

        },
        methods: {
            isSelect(nav){
                let {
                    path
                } = this.$route;
                let {
                    optionName
                } = this;
                let selectNav = [nav, ...(nav.children || [])].find(item => item.path == path || optionName == item.name);
                return selectNav && this.change(selectNav) && true
            },
            change:debounce(function(nav){
                console.log(
                    $parseTree(this.navList,(item) => {
                        return (item.name == nav.name) || (item.path && nav.path && item.path == nav.path)
                    })[0] || {}
                )
                this.$emit(
                    'change',
                    $parseTree(this.navList,(item) => {
                        return (item.name == nav.name) || (item.path && nav.path && item.path == nav.path)
                    })[0] || {}
                );
            },100)
        },
//        beforeCreate(){}, //组件实例刚被创建，组件属性计算之前，如data属性等
        created(){

            // this.barrierFreeSwitch = window !== window.top;
        }, //组件实例创建完成，属性已绑定，但DOM还未生成，属性还不存在
    //    beforeMount(){}, //模板编译/挂载之前
        mounted(){

        }, //模板编译/挂载之后(不保证组件已在document中)
        beforeUpdate(){
        }, //组件更新之前
        updated(){
        }, //组件更新之后
//        activated(){}, //for keep-alive,组件被激活时调用
//        deactivated(){}, //for keep-alive,组件被激活时调用
//        beforeDestory(){}, //组件销毁前调用
//        destoryed(){}, //组件销毁后调用
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header-wrap{
    position: sticky;
    top: 0;
    z-index:9;
    box-shadow: 2px 0 5px 2px #ccc;
}
.header-box{
    position: relative;
    z-index:99;
    background-color:#fff;
}
.header-logo{
    height:80px;
    padding:15px 20px;
}
.header-logo .logo-2{
    display: none;
}



.header-nav{
    /* width:100%; */
    height:inherit;
}
/* .nav-item{
    position:relative;
} */

.nav-item-name{
    display:block;
    padding:0 10px;
    font-size:22px;
    text-align:center;
    color:#333;
    position:relative;
}
.header-nav:hover .nav-item .nav-item-select::after{
    display:none;
}
.header-nav:hover .nav-item .nav-item-select{
    color:#333;
}
.nav-item .nav-item-select::after,
.nav-item:hover .nav-item-select::after,
.header-nav:hover .nav-item:hover > .nav-item-name::after{
    content:"";
    display:block;
    position:absolute;
    left:0;
    right:0;
    bottom:0;
    height:4px;
    background-color:rgba(27, 179, 240, 1);
}
.nav-item:hover > .nav-item-name{
    /* font-weight: bold;
    background-color:rgba(27, 179, 240, .8);
    color:rgba(255,255,255,.9); */
    color:#2FA8D0;
}
.nav-item .nav-item-select{
    color:#2FA8D0;
}
.nav-item-children{
    padding:0 10px;
    position:absolute;
    left:0;
    top:100%;
    /* min-height:80px; */
    width:100%;
    background-color:rgba(255,255,255,.8);;
    z-index:2;
    transition:all .3s;
    max-height:0;
    overflow:hidden;
}
.nav-item:hover .nav-item-children{
    max-height:200px;
}
.nav-item-child{
    padding:15px 0;
}
.nav-item-child .nav-item-name{
    line-height:22px;
    font-size: 20px;
    color:#333;
    text-align:center;
}
.nav-item-child .nav-item-child-select{
    color: #2FA8D0;
    /* font-weight:bold; */
}
.nav-item-child:hover > .nav-item-name{
    color:#2FA8D0;
    /* font-weight: bold; */
}
.icon-list{
    width:60px;
    height:60px;
    /* position:absolute;
    right:0;
    top:50%;
    transform: translateX(-50%) translateY(-50%); */
    display:none;
    text-align:center;
    line-height:60px;
    overflow:hidden;
    margin-left:10px;
}

#is-nav-list-show-129389128{
    display:none;
}
@media screen and (max-width: 1100px){
    .icon-list{
        display:block;
    }
    .header-logo {
        width:100%;
    }
    .header-box > .content-w{
        padding:0;
        display:block;
    }
    .header-nav{
        width:100%;
        height:auto;
    }
    .nav-list{
        background-color:rgba(0,0,0,.9);
        flex-direction:column;
        position:absolute;
        top:100%;
        left:0;
        right:0;
        z-index:2;
        transition: all .5s ease-in-out;
        overflow:hidden;
        height:auto;
        max-height:0;
    }
    .nav-list .nav-item{
        width:100%;
        flex:none !important;
        max-width:100% !important;
        position:relative;
    }
    .nav-list .nav-item-name{
        text-align:left;
        padding:10px 0  10px 20px;
        color:#fff;
    }
    .nav-item .nav-item-select::after,
    .nav-item:hover > .nav-item-select::after,
    .nav-item:hover > .nav-item-name::after{
        content:"";
        display:block;
        position:absolute;
        left:0;
        top:0;
        bottom:0;
        width:4px;
        height:100%;
        background-color:rgba(27, 179, 240, 1);
    }
    .header-nav:hover .nav-item .nav-item-select{
        color:#fff;
    }
    /* .nav-item:hover .nav-item-select{
        color:#;
    } */
    .nav-item-children{
        width:100%;
        /* height:60px; */
        position:relative;
        background-color: rgba(255,255,255,.8);
    }
    .nav-item-child{
        border:0;
        padding:0;
    }
    .nav-item-child .nav-item-name{
        color:#555;
    }
    .nav-item-child .nav-item-child-select{
        color:rgba(27, 179, 240, 1);
    }
    #is-nav-list-show-129389128:checked ~ .nav-list{
        max-height:500px;
        padding:15px 0;
    }
    #is-nav-list-show-129389128:checked ~ .nav-list .nav-item{

    }
}
@media screen and (max-width: 650px){

}
</style>

