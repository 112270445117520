<template>
    <div class="fixed-wrap">
        <div class="content-w float-clear">
            <auto-row class="fixed-content" mode="column" items="center">
                <auto-col class="consult active">
                    <div class="consult-icon middle"><img src="@/assets/img/zixun@2x.png" width="33" alt="在线咨询"></div>
                    <p class="consult-text">在线咨询</p>
                </auto-col>
                <auto-col>
                    <ul class="information-list">
                        <li class="information-item middle">
                            <img src="@/assets/img/dianhuo.png" alt="">
                            <div class="information-item-content">
                                <div class="tip">
                                    <a class="information-item-content-tel" href="tel:027-50669194" title="客服联系方式 027-50669194"><Icon type="ios-call" />027-50669194（客服）</a>
                                    <a class="information-item-content-tel" href="tel:18086485172"><Icon type="md-phone-portrait" />18086485172（商务）</a>
                                    <a class="information-item-content-tel" href="tel:18086462921"><Icon type="md-phone-portrait" />18086462921（技术）</a>
                                </div>
                            </div>
                        </li>
                        <li class="information-item middle">
                            <img src="@/assets/img/wx.png" alt="联系电话">
                            <div class="information-item-content">
                                <img src="@/assets/img/weixin.jpg" width="200" alt="">
                            </div>
                        </li>
                    </ul>
                </auto-col>
                <auto-col>
                    <div class="toTop middle active" @click="toTop">
                        <img src="@/assets/img/fhdb.png" width="32" alt="">
                    </div>
                </auto-col>
            </auto-row>
        </div>
    </div>
</template>
<script>
    const animatedInteger = (startValue=0, endValue=0, animationTiming=800) => {
        let startTime = new Date().valueOf();
        let num = null;
        let requestAnimationFrameId = null;
        let animate = (callback) =>  {
            let currentTime = new Date().valueOf();
            let scheduleTime = currentTime - startTime;
            num = ((scheduleTime / animationTiming) * (endValue - startValue) + startValue).toFixed(0);
            if(endValue - startValue > 0 ? num >= endValue : num <= endValue){
                num = endValue;
                // cancelAnimationFrame(requestAnimationFrameId);
            }else{
                requestAnimationFrameId = requestAnimationFrame(animate.bind(null,callback));
            }
            callback && callback(parseFloat(num))
        }
        return {
            animate:(startValue == endValue) ? () => {} : animate
        };
    }
    import {
        autoRow,
        autoCol
    } from "@/components"
    import {Icon} from 'view-design';
    export default {
        name: '',
        components: {
            autoRow,
            autoCol,
            Icon
        },
        props: [],
        data() {
            return {
            }
        },
        computed: { //计算属性

        },
        watch: {  //监听器

        },
        methods: {
            toTop(){
                let startNum = document.documentElement.scrollTop;
                animatedInteger(startNum,0, startNum / 10).animate((num) => {
                    window.scrollTo(0,num)
                })
            }
        },
//        beforeCreate(){}, //组件实例刚被创建，组件属性计算之前，如data属性等
        created(){

        }, //组件实例创建完成，属性已绑定，但DOM还未生成，属性还不存在
//        beforeMount(){}, //模板编译/挂载之前
       mounted(){
       }, //模板编译/挂载之后(不保证组件已在document中)
       beforeUpdate(){
       }, //组件更新之前
       updated(){
       }, //组件更新之后
//        activated(){}, //for keep-alive,组件被激活时调用
//        deactivated(){}, //for keep-alive,组件被激活时调用
//        beforeDestory(){}, //组件销毁前调用
//        destoryed(){}, //组件销毁后调用
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fixed-wrap{
    position:fixed;
    left:0;
    top:56%;
    width:100%;
    height:0;
    z-index:1000;
}
.fixed-wrap .content-w{
    position:relative;
    height:0;
    width:calc(100% - 240px);
    /* width */
}
.fixed-content{
    position:absolute;
    left:100%;
    width:unset !important;
}
.consult{
    text-align:center;
}
@keyframes scala_1{
    0%   {box-shadow: 0 0 0 0 #2FA8D066, 0 0 0 15px #2FA8D066, 0 0 0 25px #2FA8D033;}
    100% {box-shadow: 0 0 0 15px #2FA8D066, 0 0 0 25px #2FA8D033, 0 0 0 25px #2FA8D000;}
}
.consult-icon{
    display: inline-block;
    background-color: #2FA8D0;
    border-radius:50%;
    width:60px;
    height:60px;
    animation: scala_1 2s linear infinite;
}
.consult-text{
    font-size: 18px;
    color: #FFFFFF;
    line-height: 24px;
    width: 91px;
    height: 26px;
    background-color: #2FA8D0;
    border-radius: 13px;
    border: 1px solid #FFFFFF;
    margin-top:-8px;
    position:relative;
    z-index:1;
}
.information-list{
    width: 60px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 0px rgba(29,32,25,0.16);
    margin-top:80px;
}
.information-item{
    width:100%;
    height:60px;
    text-align:center;
    position:relative;
}

.information-item:hover>img{
    transform: scale(1.1);
}
.information-item:hover .information-item-content{
    display:block;
    position:absolute;
    right:100%;
    bottom:0;
}
.information-item::after{
   content:"";
   display:block;
   height:1px;
   width:48px;
   margin:0 auto;
   background-color:#EDEDED;
}
.information-item-content{
    display:none;
    padding-right:40px;
}
.information-item-content .tip{
    background-color:#fff;
    border-radius:4px;
    padding:0 20px;
    position:relative;
}
.information-item-content .tip::after{
    content:"";
    display: block;
    position:absolute;
    left:0;
    bottom:50px;
    border-left:40px solid #fff;
    border-top:15px solid rgba(29,32,25,0);
    border-bottom:15px solid rgba(29,32,25,0);
}
.information-item-content-tel{
    white-space: nowrap;
    font-size:20px;
    padding:15px 0;
    display: block;
}
.information-item-content-tel:nth-last-of-type(n+2){
    border-bottom:#999 1px dashed;
}
.toTop{
    width: 60px;
    height: 60px;
    text-align:center;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 0px rgba(29,32,25,0.16);
    margin-top:16px;
}
/* @media screen and (max-width: 1700px){
    .fixed-content{

    }
} */
</style>
