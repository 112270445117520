<template>
    <div class="footer-wrap">
        <div class="footer-content">
            <div class="content-w">
                <div class="float-clear">
                    <div class="footer-content-left float-left" size="300" min="300">
                        <div class="footer-content-left-icon">
                            <img class="footer-content-left-icon-1" src="@/assets/img/footer-logo-1.png" height="52" alt="">
                            <img  class="footer-content-left-icon-2" src="@/assets/img/footer-logo-2.png" height="110" alt="">
                        </div>
                        <div class="footer-content-left-item">
                            <div class="footer-content-left-item-text">7*24小时服务：18086485172</div>
                            <div class="footer-content-left-item-text">业务合作：zjh@launchso.com</div>
                        </div>
                        <div class="footer-content-left-tel-box">
                            <a class="footer-content-left-tel middle" href="tel:15927595565">
                                <img src="@/assets/img/dianhua.png" width="16" alt="">
                                <span style="margin-left:15px;">电话咨询</span>
                            </a>
                        </div>
                    </div>
                    <div class="footer-content-right float-right float-clear">
                        <div class="float-left footer-content-right-item">
                            <div class="footer-content-right-item-label">解决方案</div>
                            <div class="footer-content-right-item-text-box">
<!--                                <router-link :to="{path:'/'}">-->
                                         <p class="footer-content-right-item-text  pointer" @click="clickItem()">智慧养老院解决方案</p>
<!--                                </router-link>-->
                                <router-link :to="{path:'/scheme/2'}">
                                    <p class="footer-content-right-item-text">家庭养老床位解决方案</p>
                                </router-link>
                                <router-link :to="{path:'/scheme/3'}">
                                    <p class="footer-content-right-item-text">居家养老解决方案</p>
                                </router-link>
                                <router-link :to="{path:'/scheme/sqyl'}">
                                    <p class="footer-content-right-item-text">社区养老解决方案</p>
                                </router-link>
<!--                                <router-link :to="{path:'/'}">-->
                                    <p class="footer-content-right-item-text  pointer" @click="clickItem()">设备物联解决方案</p>
<!--                                </router-link>-->
<!--                                <router-link :to="{path:'/'}">-->
                                    <p class="footer-content-right-item-text  pointer"  @click="clickItem()">养老评估解决方案</p>
<!--                                </router-link>-->
                                <router-link :to="{path:'/scheme/4'}">
                                    <p class="footer-content-right-item-text">机构养老解决方案</p>
                                </router-link>
                            </div>
                        </div>
                        <div class="float-left footer-content-right-item">
                            <div class="footer-content-right-item-label">产品中心</div>
                            <div class="footer-content-right-item-text-box">
                                <router-link :to="{path:'/product/1'}">
                                    <p class="footer-content-right-item-text">智慧养老平台</p>
                                </router-link>
                                <router-link :to="{path:'/product/3'}">
                                    <p class="footer-content-right-item-text">移动应用平台</p>
                                </router-link>
                                <router-link :to="{path:'/product/2'}">
                                    <p class="footer-content-right-item-text">智能硬件支持</p>
                                </router-link>
                                <router-link :to="{path:'/product/4'}">
                                    <p class="footer-content-right-item-text">可视化大屏</p>
                                </router-link>
                            </div>
                        </div>
                        <div class="float-left footer-content-right-item">
                            <div class="footer-content-right-item-label">服务</div>
                            <div class="footer-content-right-item-text-box">
                                <router-link :to="{path:'/serve/1'}">
                                    <p class="footer-content-right-item-text">24小时呼叫中心</p>
                                </router-link>
                                <router-link :to="{path:'/serve/2'}">
                                    <p class="footer-content-right-item-text">辅具租赁</p>
                                </router-link>
                                <router-link :to="{path:'/serve/3'}">
                                    <p class="footer-content-right-item-text">适老化改造</p>
                                </router-link>
                            </div>
                        </div>
                        <div class="float-left footer-content-right-item">
                            <div class="footer-content-right-item-label">关于我们</div>
                            <div class="footer-content-right-item-text-box">
                                <router-link :to="{path:'/aboutUs/1'}">
                                    <p class="footer-content-right-item-text">公司介绍</p>
                                </router-link>
                                <router-link :to="{path:'/aboutUs/2'}">
                                    <p class="footer-content-right-item-text">加入我们</p>
                                </router-link>
                                <router-link :to="{path:'/aboutUs/3'}">
                                    <p class="footer-content-right-item-text">联系我们</p>
                                </router-link>
                                <router-link :to="{path:'/aboutUs/4'}">
                                    <p class="footer-content-right-item-text">新闻</p>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="float-clear">
                    <div class="float-left float-clear footer-content-left middle">
                        <div class="footer-content-left-item"><img src="@/assets/img/header-logo.png" height="52" alt=""></img></div>
                        <div class="footer-content-left-item">7*24小时服务：18086485172</div>
                        <div class="footer-content-left-item">业务合作：zjh@launchso.com</div>
                        <div class="footer-content-left-item">
                            <a href="javascript:;" class="middle">
                                <img src="@/assets/img/dianhua.png" width="16" alt="">
                                <span>免费拨打电话</span>
                            </a>
                        </div>
                    </div>
                    <div class="float-right footer-content-right">
                        <div style="min-width:806px; background-color:red; height:50px;"></div>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="copyright-wrap">
            <div class="content-w">
                <div class="copyright middle">
                    <span>Copyright ©2022 朗数科技有限公司</span>
                    <a href="http://beian.miit.gov.cn/" class="copyright-link" target="_blank"> 鄂ICP备15008319号-1</a>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
    import {
        autoRow,
        autoCol
    } from "@/components"
    export default {
        name: '',
        components: {
            autoRow,
            autoCol
        },
        props: [],
        data() {
            return {
            }
        },
        computed: { //计算属性

        },
        watch: {  //监听器

        },
        methods: {
            clickItem(){
                this.$Msg.warning('正在开发中！')
            }
        },
//        beforeCreate(){}, //组件实例刚被创建，组件属性计算之前，如data属性等
        created(){

        }, //组件实例创建完成，属性已绑定，但DOM还未生成，属性还不存在
//        beforeMount(){}, //模板编译/挂载之前
       mounted(){
       }, //模板编译/挂载之后(不保证组件已在document中)
       beforeUpdate(){
       }, //组件更新之前
       updated(){
       }, //组件更新之后
//        activated(){}, //for keep-alive,组件被激活时调用
//        deactivated(){}, //for keep-alive,组件被激活时调用
//        beforeDestory(){}, //组件销毁前调用
//        destoryed(){}, //组件销毁后调用
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer-wrap{

}
.footer-content{
    background-color:#031318;
}
.footer-content .content-w{
    padding:40px 0;
}
.footer-content-right-item{
    margin-right:94px;
}
.footer-content-right-item-label{
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 21px;
}
.footer-content-right-item-text-box{
    margin-top:35px;
}
.footer-content-right-item-text{
    font-size: 16px;
    color: #FFFFFF;
    line-height:30px;
}
.copyright-wrap{
    height: 6vw;
    max-height:88px;
    min-height:60px;
    background-color:#0F1407;
}

.copyright-link{
    display: inline;
}

.copyright-link:hover{
    opacity: 0.9;
}


.copyright-wrap .content-w{
    height:100%;
}
.footer-content-left-icon-2{
    display:none;
}
.copyright{
    height:100%;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #FFFFFF;
    line-height: 19px;
    text-align:center;
}
.footer-content-left,
.footer-content-right{
    padding:20px 0 0;
}
.footer-content-left-item{
    font-size: 16px;
    color: #FFFFFF;
    line-height: 21px;
    padding:40px 0 30px;
}
.footer-content-left-item .footer-content-left-item-text:nth-of-type(n+2){
    margin-top:20px;
}
.footer-content-left-tel{
    font-size: 16px;
    color: #FFFFFF;
    line-height: 24px;
    width: 200px;
    height: 50px;
    background: #2FA8D0;
    border-radius: 4px;
    border: 1px solid #2FA8D0;
    display:block;
    text-align:center;
}
@media screen and (max-width: 1200px){
    .footer-content-left,
    .footer-content-right{
        width:100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .footer-content-left-icon-1{
        display:none;
    }
    .footer-content-left-icon-2{
        display:unset;
    }
    .footer-content-right::after{
        display: none;
    }
    .footer-content-left-item{
        padding:0 40px;
    }
    .footer-content-right-item{
        margin-right:0;
    }
}
</style>
